.schedule-document {
  width: 100%;
  max-width: 760px;
  margin: 0 auto;
  padding: 24px;
  padding-right: 14px;

  section.document-head {
    display: flex;
    align-items: center;
    padding-bottom: 20px;

    h1 {
      font-size: 28px;
      font-weight: 750;
      letter-spacing: -0.2px;
      margin: 0;
      line-height: 1.2;
    }

    button {
      font-size: 14px;
      margin-left: auto;
      margin-right: 24px;
      font-weight: 540;
      color: var(--medium-gray);

      &:hover {
        color: var(--primary-blue);
      }
    }
  }

  section {
    margin-bottom: 15px;

    header {
      display: inline-flex;
      align-items: center;
      margin-bottom: 8px;
      cursor: pointer;
      
      .toggle {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 10px 0 0;
        width: 20px;
        height: 20px;
  
        svg {
          display: block;
          width: 10px;
          height: 10px;
        }
      }
    
      h2 {
        display: flex;
        align-items: center;
        margin: 0;
        font-size: 20px;
        font-weight: 700;
        line-height: 1;
        letter-spacing: -0.2px;

        a {
          color: var(--dark-gray);
          // text-decoration: none;
          // transition: color 0.2s;

          // &:hover {
          //   color: var(--primary-blue);
          // }
        }
        .weather-icon {
          display: inline-block;
          margin-left: 6px;
          width:  calc(1em + 8px);
          height: calc(1em + 8px);
  
          img {
            display: inline-block;
            width: 100%;
            height: 100%;
          }
        }
      }

    }
  
    .section-inner {
      width: 100%;
      border-left: 3px solid var(--extra-light-gray);
      margin-left: 3px;
      transition-duration: 100ms, 180ms;
      transition-property: opacity, max-height;
      transition-timing-function: ease-out;

      &.closed {
        opacity: 0;
        max-height: 0;
        overflow: hidden;
      }
      
      &.open {
        opacity: 1;
        max-height: 1000px;
      }
    }
  }

  .block-group {
    display: inline-flex;
    flex-direction: column;
    background: var(--extra-light-gray);
    margin-left: 10px;
    margin-bottom: 6px;
    border-radius: 5px;
    padding: 10px 10px 4px 10px;
    
    .block-group-header {
      display: flex;
      align-items: center;
      margin-bottom: 6px;
      user-select: none;
      cursor: pointer;

      .toggle {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
  
        svg {
          display: block;
          width:  8px;
          height: 8px;
        }
      }

      .block-group-name {
        padding: 0 4px;
        font-size: 13px;
        font-weight: 600;
        line-height: 1;
      }
    }

    .block-group-inner {
      transition-duration: 180ms;
      transition-property: opacity, max-height;
      max-height: 600px;

      .team-member-block {
        padding-left: 16px;
        
        .team-member-highlight-menu {
          left: 6px;
        }
      }

    }
    
    &.closed {
      .block-group-inner {
        opacity: 0;
        max-height: 0;
        padding-top: 0;
        overflow: hidden;
        transition-timing-function: ease-out;
      }
    }
    
    &.open {
      .block-group-inner {
        opacity: 1;
        max-height: 600px;

        transition-timing-function: ease-out;
      }
    }

    &.empty {
      .empty-msg {
        font-size: 13px;
        font-weight: 500;
        color: var(--medium-gray);
        padding: 6px 0;
        display: inline-block;
      }
      &.closed {
        background: transparent;
  
        .block-group-name {
          color: var(--medium-gray);
        }
  
        svg path {
          fill: var(--medium-gray);
        }
      }
    }
  }

  .schedule-day.hovered, .block-group.hovered, .JobWrap.hovered {
    background-color: pink; /* Example color */
    // border: 2px dashed #ccc; /* Example border */
  }
}