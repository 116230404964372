.modal-mask {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(1.5px) grayscale(1);
  z-index: 9999;
}
.modal {
  position: fixed;
  padding: var(--modal-padding);
  // transform: translate(-50%, -50%); // this is overridden by the animation
  z-index: 10000;
  box-shadow: 0 0px 40px 0 rgba(0, 0, 0, 0.4);
  background: #fff;
  border-radius: 14px;
  overflow: hidden;

  .close-x {
    position: absolute;
    top:   0;
    right: 0;
    border-radius: 0 5px 0 22px;
    width:  46px;
    height: 46px;
    background: white;
    padding: 12px;
    cursor: pointer;
    color: var(--medium-gray);
    transition: color 0.2s;
    z-index: 10001;
    
    &:hover {
      color: var(--dark-gray);
    }

    @media (max-width: 390px) {
      left: 0;
    }
  }
}