@use '/src/styles/jobHighlights.scss' as *;
@use '/src/styles/mixins.scss' as *;

.job-block {
  z-index: 1;
  border-radius: 5px;
  position: relative;
  // cursor: move;
  
  .job-block-line {
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 18px;
    user-select: none;
  }

  &.active {
    @include activeBlock;

    // .job-block-line {
    //   @include activeBlock;
    // }
  }
  &.selected {
    .job-block-line {
      @include selectedBlock;
    }
  }

  // &:hover {
  //   background-color: rgb(245, 245, 242);
  // }

  .job-title-wrap {
    display: flex;
    flex: 1 0 350px;    
    text-overflow: ellipsis;
  }

  .job-title {
    display: flex;
    gap: 6px;
    align-items: baseline;
    font-size: 15px;
    letter-spacing: -0.3px;
    font-weight: 400;    
    line-height: 20px;
    padding: 6px;
    border-radius: 5px;
    white-space: nowrap;
    cursor: default;

    .client-last-name, .map-link {
      border-bottom-style: solid;
      border-bottom-width: 1.7px;
      border-bottom-color: var(--light-gray);
      transition: border-bottom-color 140ms, box-shadow 140ms;
      
      &:hover {
        box-shadow: 0 1.3px 0 0 var(--light-gray);
      }
    }

    .client-last-name {
      font-weight: 700;
      cursor: pointer;
      
      &:hover {
        border-bottom-color: var(--jobber-green);
        box-shadow: 0 1.3px 0 0 var(--jobber-green);
      }
    }

    .map-link {
      cursor: zoom-in;
      
      &:hover {
        border-bottom-color: var(--primary-blue);
        box-shadow: 0 1.3px 0 0 var(--primary-blue);
      }
    }

    .custom-fields {
      display: flex;
      gap: 6px;
      align-items: center;

      .field {
        font-size: calc(1em - 3px);
        font-weight: 500;
        border: 1px solid var(--medium-gray);
        background-color: var(--extra-light-gray);
        border-radius: 4px;
        padding: 2px 3px;
        line-height: 1;
        -webkit-text-fill-color: var(--dark-gray);
      }
    }
  }
  @each $id, $highlight in $jobHighlights {
    &.highlight-#{$id} .job-title {
      @if $id == 1 {
        color: var(--text-default);
        background: $highlight;
      } @else {
        background: $highlight;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        -webkit-box-decoration-break: clone;
      }
    }
  }

  // @each $id, $highlight in $jobHighlights {
  //   &.highlight-#{$id} .job-title {
  //     background: $highlight;
  //     background-clip: text;
  //     -webkit-background-clip: text;
  //     -webkit-text-fill-color: transparent;
  //     box-decoration-break: clone;
  //     -webkit-box-decoration-break: clone;
  //   }
  // }

  .time-inputs {
    margin-left: auto;
    display: flex;
    flex-wrap: nowrap;
    margin-right: 14px;

    input[type="time"] {
      background: var(--extra-light-gray);
      border: 1px solid var(--light-gray);
      border-radius: 6px;
      font-size: 12px;
      font-family: Inter, sans-serif;
      text-align: right;
      font-weight: 640;
      letter-spacing: -0.5px;
      word-spacing: -2px;
      color: var(--dark-gray);
      cursor: pointer;

      &:hover {
        background: var(--light-gray);
      }

      &:focus {
        background: #ffffff;
        color: var(--dark-gray);
      }

      &::selection {
        background-color: var(--extra-light-gray);
      }
    }
    span {
      font-weight: 640;
      padding: 0 2px;
    }
  }

  .job-info-button {
    position: absolute;
    right: 30px;
    color: var(--medium-gray);
    width:  20px;
    height: 20px;
    transition: color 0.2s;

    &:hover {
      color: var(--primary-blue);
    }

    svg {
      @include svgIcon;
    }
  }

  .job-highlight-toggle {
    position: absolute;
    color: var(--medium-gray);
    right: 6px;
    width:  16px;
    height: 16px;
    transition: color 0.2s;
    cursor: context-menu;

    &:hover {
      color: var(--dark-gray);
    }

    svg {
      @include svgIcon;
    }

    &.menu-active {
      color: var(--dark-gray);
    }
  }
}

.job-highlight-menu {
  padding: 5px;
  position: absolute;
  z-index: var(--menu-zindex);
  right: -7px;
  top: 27px;
  background: #ffffff;
  border-radius: 5px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.4);

  li {
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: flex-end;
    padding: 5px 6px;
    border-radius: 4px;

    &:hover {
      background-color: var(--extra-light-gray);
    }

    .highlight-box {
      margin-left: 6px;
      height: 20px;
      width: 26px;
      border-radius: 4px;
    }
    span {
      @include menuItemText;
    }
  }

  // Loop through the highlights and apply the color to the highlight-box
  @each $id, $highlight in $jobHighlights {
    .highlight-#{$id} .highlight-box {
      background: $highlight;
    }
  }
}
