@use '/src/styles/mixins.scss' as *;

.jobvisit-confirmation-status-selector {
  display: inline-flex;
  position: relative;
  font-size: 15px;
  letter-spacing: -0.3px;
  font-weight: 400;
  padding: 10px;
  border-radius: 5px;
  background-color: var(--extra-light-gray);

  .toggle {
    display: flex;
    align-items: center;
    cursor: context-menu;

  }

  &:hover {
    background-color: var(--extra-light-gray);
  }

  .icon {
    margin-right: 8px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 18px;
    width:  18px;
    // color: var(--medium-dark-gray);
  
    svg {
      height: 100%;
      width: 100%;
    }
  }

  .text {
    font-weight: 600;
    white-space: nowrap;
  }

  
  .menu {
    position: absolute;
    padding: 5px;
    z-index: var(--menu-zindex);
    left: 0px;
    top: 30px;
    background: #ffffff;
    border-radius: 5px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.4);
    
    li {
      display: flex;
      align-items: center;
      cursor: pointer;
      padding: 5px;
      border-radius: 4px;
      
      &:hover {
        background-color: var(--extra-light-gray);
      }
      
      .icon {
        margin-right: 8px;
      }
      .text {
        @include menuItemText;
      }
    }
  }

      // When we use the no-text option prop
  &.no-text {
    background-color: transparent;

    &:hover {
      background-color: transparent;
    }
    padding: 4px;

    .icon {
      margin-right: 0px;
    }
    .menu {
      left: -6px;
      top: 24px;
    }
  }
}