.top-bar {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 16px;

  .title {
    font-weight: 900;
    line-height: 1;
    font-size: 16px;
    letter-spacing: 0.15px;
    color: var(--medium-dark-gray);
  }
  button {
    background: none;
    color: var(--medium-dark-gray);
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    line-height: 0;
    height: auto;

    svg {
      width: 100%;
      height: 100%;
      display: block;
    }

    &.sidebar-toggle,
    &.split-view-toggle {
      width: 24px;
      height: 20px;
      transition: color 0.2s;

      &:hover {
        color: var(--dark-gray);
      }
    }
    
    &.sidebar-toggle {
      margin-right: 10px;
    }

    &.sync-with-jobber {
      width: 141px;
      height: 18px;
      margin-left: auto;

      &:hover {
        color: rgb(50, 130, 28);
      }
    }
  }
}
