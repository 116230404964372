.time-range-selector {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: 10px;

  .time-range-selector-trigger {
    width: 100%;
  }
}