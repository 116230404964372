:root {
  /////////////////////////////////
  // Dimensions
  --sidebar-width: 220px;
  --modal-padding: 24px;

  /////////////////////////////////
  // Colors
  --app-bg: #e7e6e3;
  --primary-blue: #0087ff;
  --primary-blue-half: rgba(0, 135, 255, 0.5);
  --primary-blue-darker: #005cad;
  --jobber-green: #32821c;
  --jobber-green-darker: #2c7319;
  --dark-gray: #333333;
  --medium-dark-gray: #5e5c5c;
  --medium-gray: #bdbdbd;
  --light-gray: #d8d8d8;
  --extra-light-gray: #efefef;
  --role-blue: #63A2FC;
  --role-brown: #A87360;
  --role-green: #2BAA2E;
  --role-orange: #FF7F00;
  --role-pink: #E141D9;
  --role-purple: #9D4DF2;
  --role-red: #FB3A25;

  --text-default: var(--dark-gray);

  /////////////////////////////////
  // Layering
  --active-block-zindex: 1000;
  --menu-zindex: 5000;
  --modal-zindex: 10000;
  --box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.4);
}
$jobber-green: #32821c;