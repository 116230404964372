.event-editor {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 288px;
  align-self: flex-start;

  font-size: 18px;
  font-weight: 700;

  * {user-select: none !important;}

  .descriptive-text {
    font-size: 16px;
    font-weight: 600;
    padding-left: 4px;
  }
}