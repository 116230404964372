.app-frame {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: var(--app-bg);

  .content-wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    padding-right: 6px;
    overflow: hidden;

    .main-content {
      flex: 1;
      background: #ffffff;
      border: 1px solid #cacac7;
      border-bottom: 0;
      border-radius: 6px 6px 0 0;
      box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
      overflow-y: auto;
    }
  }
}
