@use '/src/styles/variables.scss' as *;
@use '/src/styles/mixins.scss' as *;
@use '/src/styles/jobHighlights.scss' as *;

$gap: 20px;

.job-details-view {
  max-height: calc(100vh - (var(--modal-padding) * 2) - 40px);
  width: 70vw;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  overflow-x: visible;

  @media (max-width: 767px) {
    width: calc(96vw - var(--modal-padding) * 2);
  }
  @media (max-width: 390px) {
    width: calc(100vw - var(--modal-padding) * 2);
    height: 90vh;
  }

  header {
    display: flex;
    align-items: center;
    gap: 16px;    
    font-size: 16px;
    font-weight: 600;
    letter-spacing: -0.3px;
    margin-bottom: 24px;

    .jobber-link {
      @include link-styles($color: $jobber-green);
      @include link-hover($hide: true);
      border-bottom-color: transparent;
      display: flex;
      align-items: center;
      white-space: nowrap;
      
      svg {
        margin-left: 4px;
      }
    }

    .job-title {
      padding: 0px;
      margin: 0;
      border-radius: 5px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: default;

      
      // Loop through the highlights and apply the color to the job-title
      @each $id, $highlight in $jobHighlights {
        &.highlight-#{$id} {
          background: $highlight;
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          box-decoration-break: clone;
          -webkit-box-decoration-break: clone;
        }
      }
    }
  }

  .container {
    display: flex;
    flex: 1;
    gap: calc($gap/2);
    overflow: hidden;

    @media (max-width: 450px) {
      flex-direction: column;
    }
  }

  .column {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: $gap;

    &.left {
      // background: pink;
    }

    &.right {
      // background: lightblue;
    }
  }

  .label {
    display: block;
    font-weight: 700;
    line-height: 1;;
    margin-bottom: 6px;

    // margin-bottom: 8px;
  }

  .secondary {
    font-size: 14px;
    color: var(--medium-gray);
    font-style: italic;
  }

  .date-time {
    .date {
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 6px;
    }

    .time-inputs {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      margin-right: 16px;
      margin-left: auto;

      input[type="time"] {
        background: var(--extra-light-gray);
        border: 1px solid var(--light-gray);
        border-radius: 6px;
        padding: 3px;
        font-size: 13px;
        font-weight: bold;
        font-family: Inter, sans-serif;
        text-align: right;
        letter-spacing: -0.5px;
        word-spacing: -2px;
        color: var(--dark-gray);
        cursor: pointer;

        &:hover {
          background: var(--light-gray);
        }

        &:focus {
          background: #ffffff;
          color: var(--dark-gray);
        }

        &::selection {
          background-color: var(--extra-light-gray);
        }
      }

      span {
        font-weight: 800;
      }
    }
  }

  .fields-and-instructions {
    display: flex;
    flex-direction: column;
    // flex-grow: 1;
    overflow-y: scroll;
    gap: $gap;

    .fields {
      display: flex;
      flex-direction: column;
      gap: 3px;

      .label {display: inline-block;}
    }

    .instructions {
      line-height: 1.3;

    }
  }

  .client-details {
    display: flex;
    flex-direction: column;    

    a {
      color: inherit;
      border-bottom-color: transparent;
    }

    .client-name {
      line-height: 1;
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 4px;
    }

    .client-location {
      font-size: 14px;
      font-weight: 700;
      line-height: 1.4;
      margin-bottom: 4px;

      a {
        @include link-styles($color: var(--dark-gray));
        @include link-hover($color: var(--primary-blue));
      }
    }

    .client-contact-info {
      line-height: 1.4;

      a {
        @include link-hover($color: var(--primary-blue));
      }
    }
  }

  .assigned-team-members{
    line-height: 1.4;

    ul {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;

      .team-member {
        margin-top: 8px;
        text-align: center;
        font-size: 12px;
        font-weight: 600;

        .avatar {
          width: 32px;
          height: 32px;
          border-radius: 50%;
          overflow: hidden;
          background: var(--light-gray);
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 700;
          color: var(--medium-dark-gray);

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }
}