@use '/src/styles/mixins.scss' as *;

// Team Member Highlights
// { id: "1", name: "Climber, level 1" },
// { id: "2", name: "Climber, level 2" },
// { id: "3", name: "Pruner " },
// { id: "4", name: "Shearer" },
// { id: "5", name: "Ground Support" },
// { id: "6", name: "Remover" },
// { id: "7", name: "Feller" },
// { id: "8", name: "Equipment Operator" },
$teamMemberHighlights: (
  1: #FF7F00, 
  2: #2BAA2E, 
  3: #E141D9, 
  4: #9D4DF2, 
  5: #A87360, 
  6: #FB3A25, 
  7: #FB3A25,
  8: #63A2FC
);

.team-member-block {
  display: flex;
  align-items: center;
  position: relative;
  padding: 4px 4px 4px 42px;
  border-radius: 5px;

  &.active {
    @include activeBlock;
  }

  &.selected {
    @include selectedBlock;
  }

  .team-member-name {
    font-size: 15px;
    font-weight: 350;
    user-select: none;
    cursor: text;
  }
  
  // Loop through the highlights and apply the color to the name
  @each $id, $highlight in $teamMemberHighlights {
    &.highlight-#{$id} .team-member-name, 
    &.highlight-#{$id} input
    {
      color: $highlight;
    }

  }
  
  .team-member-highlight-toggle {
    margin-left: 4px;
    opacity: 0.2;
    cursor: context-menu;

    svg {
      width: 12px;
      position: relative;
      top: 1px;
    }
  }

  &.editable {
    &.editable {
      padding: 4px 4px 6px 42px;
  
      .input-wrap {      
        display: inline;
        // background: pink;
  
        input {
          caret-color: red;
          padding: 0px;
          font-size: 15px;
          position: relative;
          height: 15px;
          border: none;
          background: transparent;
          font-weight: 500;
    
          &:focus {
            outline: none;
          }
        }
      }

      .team-member-highlight-toggle {
        display: none;
      }
    }
  }

  &.menu-active {
    .team-member-highlight-toggle {
      opacity: 1;
    }
  }

  &:hover {
    .team-member-highlight-toggle {
      opacity: 1;
    }
  }
}

.team-member-highlight-menu {
  position: absolute;
  padding: 5px;
  z-index: 1000;
  left: 30px;
  top: 22px;
  background: #ffffff;
  border-radius: 5px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.4);

  li {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 5px 6px;
    border-radius: 4px;
    white-space: nowrap;
    
    &:hover {
      background-color: var(--extra-light-gray);
    }

    .highlight-box {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      margin-right: 6px;
      height: 20px;
      width: 26px;
      border-radius: 4px;
    }

    span {
      @include menuItemText;
    }
  }

  // Loop through the highlights and apply the color to the highlight-box
  @each $id, $highlight in $teamMemberHighlights {
    .highlight-#{$id} .highlight-box {
      background: $highlight;
    }
  }
}
